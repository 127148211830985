import logo from './logo.svg';
import { getTenantName } from './utils';
import './App.css';

function App() {
  const tenantName = getTenantName();
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome, {tenantName}!</h1>
        {/* Additional homepage content */}
      </header>
    </div>
  );
}

export default App;
